<template xmlns="http://www.w3.org/1999/html">
  <div class="card card-custom gutter-b">
    <div class="card-header">
      <div class="card-title">
        Danh sách buổi nghỉ học
      </div>
      <div class="card-title">
        <el-button type="button" class="btn btn-sm btn-success font-weight-bold mr-2" @click="dialogAddDateOffLesson=true"><i style="padding: 0" class="fa fa-plus"></i> Thêm mới</el-button>
      </div>
    </div>
    <div class="card-body">
      <div class="example mb-10">
        <div class="row filter_form">
          <div class="col-xxl-3 col-md-4">
            <label for=""><b>Trung tâm</b></label>
            <div class="form-group w-100">
              <el-select v-model="query.center_id" class=" w-100" placeholder="Chọn trung tâm" @change="getBranches" clearable>
                <el-option v-for="item in centers" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="col-xxl-3 col-md-4">
            <label for=""><b>Chi nhánh</b></label>
            <div class="form-group w-100">
              <el-select v-model="query.branch_id" class=" w-100" placeholder="Chọn chi nhánh" clearable>
                <el-option v-for="item in branches" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="col-xxl-3 col-md-4">
            <label for=""><b>Lớp học</b></label>
            <div class="form-group w-100">
              <input type="text" v-model="query.class_name" class="form-control w-100" placeholder="Nhập tên lớp">
            </div>
          </div>
          <div class="col-xxl-3 col-md-4">
            <label for=""><b>Giáo viên</b></label>
            <div class="form-group w-100">
              <input type="text" v-model="query.teacher_name" class="form-control w-100" placeholder="Nhập tên giáo viên">
            </div>
          </div>
          <div class="col-xxl-3 col-md-4">
            <label for=""><b>Phân loại</b></label>
            <div class="form-group w-100">
              <el-select  v-model="query.type" class=" w-100" placeholder="Chọn trạng thái" clearable>
                <el-option label="GV xin nghỉ" :value="2"> </el-option>
                <el-option label="Admin cho nghỉ" :value="1"> </el-option>
              </el-select>
            </div>
          </div>
          <div class="col-xxl-3 col-md-4">
            <label for=""><b>Trạng thái</b></label>
            <div class="form-group w-100">
              <el-select v-model="query.status" class="w-100"  placeholder="Chọn trạng thái" clearable>
                <el-option label="Đã từ chối" :value="2"> </el-option>
                <el-option label="Chưa duyệt" :value="0"> </el-option>
                <el-option label="Đã duyệt" :value="1"> </el-option>
                <el-option label="Đã huỷ" :value="3"> </el-option>
              </el-select>
            </div>
          </div>

          <div class="col-xxl-3 col-md-4">
            <label for="">&ensp;</label>
            <div class="form-group">
              <button @click="getListRequestOffClass()" class="btn btn-primary font-weight-bold mr-2">
                <i class="fa fa-search"></i>Tìm kiếm </button>
            </div>
          </div>
        </div>
        <div class="example-preview table-responsive">
          <table class="table table-vertical-center table-bordered"
          v-loading="loading"
          element-loading-text="Loading..."
          >
            <thead>
              <tr style="background-color: #F8F8F8;">
                <th>Mã</th>
                <th style="width: 400px">Lý do nghỉ</th>
                <th>Phân loại</th>
                <th style="width: 100px">Lớp học</th>
                <th>Trạng thái</th>
                <th>Thời gian duyệt nghỉ</th>
                <th>Hành động</th>
              </tr>
            </thead>
            <tbody v-if="data?.length > 0">
              <tr v-for="(item, index) in data" :key="item?.id">
                <td>{{ item?.id }}</td>
                <th>{{ item?.reason_off }}</th>
                <td>
                  <div v-if="item?.type == 2">GV xin nghỉ</div>
                  <div v-if="item?.type == 1">Admin cho nghỉ</div>
                </td>
                <td>{{ item.class_name.length > 20 ? item.class_name.slice(0, 20) + ' ...' : item.class_name}}
                </td>
                <td>
                  <div>
                    <span class="badge" :style="getStatus(item.status).style">{{ getStatus(item.status).name }}</span>
                  </div>
                </td>
                <td>
                  <div v-if="item?.time_allow_rest && item?.time_allow_rest !== '0000-00-00 00:00:00'">
                    {{ item?.time_allow_rest }}
                  </div>
                </td>
                <td class="">
                    <a v-if="item?.status !== 0" title="Xem chi tiết" @click="actionDetail(item)" href="javascript:"
                      class="btn btn-sm ml-1 btn-icon btn-outline-primary"> <i class="fas far fa-eye p-0"></i></a>

                    <a v-if="item?.status == 0" title="Xét duyệt" @click="actionApprove(index, item)" href="javascript:"
                      class="btn btn-sm ml-1 btn-icon btn-outline-primary"> <i class="fas far fa-edit p-0"></i></a>
                  
                    <a v-if="item?.status == 1" title="Hủy buổi nghỉ" @click="actionDeleteLesson(index, item)" href="javascript:"
                      class="btn btn-sm ml-1 btn-icon btn-outline-danger">
                      <i class="fas fa-trash"></i>
                    </a>

                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="9" class="event-title no-data">Chưa có dữ liệu</td>
              </tr>
            </tbody>
          </table>

        </div>
        <div  class="position-absolute mt-3" style="right: 40px;"> Tổng số bản ghi: <b  style="color: rgb(54, 153, 255);">{{ totalData }}</b></div>
      </div>
    </div>
    <div class="edu-paginate mx-auto text-center">
      <paginate v-model="page" :page-count="last_page" :page-range="3" :margin-pages="1" :click-handler="clickCallback"
        :prev-text="'Trước'" :next-text="'Sau'" :container-class="'pagination b-pagination'" :pageLinkClass="'page-link'"
        :next-link-class="'next-link-item'" :prev-link-class="'prev-link-item'" :prev-class="'page-link'"
        :next-class="'page-link'" :page-class="'page-item'">
      </paginate>
    </div>
    <el-dialog title="Duyệt đề xuất" :visible.sync="dialogFormVisible" width="40%">
      <div v-loading="isApproveDetail">
        <el-descriptions labelClassName="w-25" contentClassName="w-75" :column="1">
          <el-descriptions-item label="Giảng viên" width="">{{ item.teacher_name }}</el-descriptions-item>
          <el-descriptions-item label="Lớp">{{ item.class_name }}</el-descriptions-item>
          <el-descriptions-item label="Trung tâm">{{ item.center_name }}</el-descriptions-item>
          <el-descriptions-item label="Chi nhánh"> {{ item.branch_name }} </el-descriptions-item>
        </el-descriptions>

        <p>Nội dung đề xuất</p>
        <el-input type="textarea" autosize readonly :value="item.reason_off"></el-input>
        <p v-if="item.class_type != 'school_review'" class="pt-4">Lộ trình học sẽ được thay đổi như sau</p>

        <p class="border p-4" v-if="item.class_type != 'school_review'">
            <span>
                {{ item.content ? item.content : `Buổi học ${ item.lesson } sẽ được chuyển từ ngày ${ item.old_schedule } sang ${ item.new_schedule }` }}
            </span>
        </p>
          <p v-else class="p-4"></p>
        <span slot="footer" class="dialog-footer d-flex justify-content-center">
          <el-button type="danger" @click="refuseRequestOffClass">Từ chối</el-button>
          <el-button :loading="loading_btn_accept" type="primary" @click="acceptRequestOffClass">Đồng ý</el-button>
        </span>
      </div>
    </el-dialog>

    <!-- dialog xoá order duyệt buổi nghỉ -->
    <el-dialog :title="`${!loading_btn_accept ? 'Hủy buổi nghỉ' : 'Vui lòng đợi'}`" :visible.sync="dialogDeleteChangeLesson" width="30%" style="color: #000; line-height: 10px; margin-top: 25vh;">
      <div v-if="!loading_btn_accept">
        <div class="title-delete">
          <hr>
          <p style="line-height: normal; word-break: break-word">Hủy buổi nghỉ sẽ thay đổi lộ trình học hiện tại.</p><br>
          <p>Bạn chắc chắn muốn hủy chứ?</p>
        </div>
        <span slot="footer" class="dialog-footer d-flex justify-content-end btn-footer">
          <el-button style="color: #000; background-color: #E6E6E6;" @click="dialogDeleteChangeLesson = false">Từ chối</el-button>
          <el-button style="color: #fff; background-color: #500DA7;" type="primary" @click="acceptDeleteChangeLesson">Xác nhận</el-button>
        </span>
      </div>
      <el-progress v-else class="mx-auto d-flex" style="width: fit-content" type="circle" :percentage="percent_loading"></el-progress>
    </el-dialog>

    <el-dialog title="Xem chi tiết đề xuất" :visible.sync="dialogDetailVisible" width="60%">
      <div v-if="detailOffLesson">
        <el-descriptions labelClassName="w-25" contentClassName="w-75" :column="1">
          <el-descriptions-item label="Lý do nghỉ" width="">{{ detailOffLesson?.parent?.reason_off }}</el-descriptions-item>
          <el-descriptions-item label="Trạng thái">{{ getStatus(detailOffLesson?.parent?.status)?.name }}</el-descriptions-item>
          <el-descriptions-item label="Thời gian duyệt nghỉ">{{ detailOffLesson?.parent?.time_allow_rest }}</el-descriptions-item>
        </el-descriptions>
        <div class="example-preview table-responsive overflow-auto mt-3">
          <table :aria-busy="isBusy" class="table table-hover table-bordered table-vertical-center b-table"
            style="text-align: center">
            <thead>
              <tr>
                <th scope="col" class="min-w-100px">STT</th>
                <th scope="col" class="min-w-100px">Lớp</th>
                <th scope="col" class="min-w-100px">Chi nhánh</th>
                <th scope="col" class="min-w-100px">Giảng viên</th>
                <th scope="col" class="min-w-100px">Ngày nghỉ</th>
                <th scope="col" class="min-w-120px">Buổi học</th>
                <th scope="col" class="min-w-120px">Phân loại</th>
              </tr>
            </thead>
            <tbody v-if="isBusy">
              <tr role="row" class="b-table-busy-slot">
                <td colspan="16" role="cell" class="">
                  <div class="text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr v-for="(item, index) in detailOffLesson.data" :key="index">
                <td> {{ index + 1 + (detailOffLesson.pagination.current_page - 1) * (detailOffLesson.pagination.per_page) }} </td>
                <td>{{ item.class_name }}</td>
                <td>{{ item.branch_name }}</td>
                <td>{{ item.teacher_name }} </td>
                <td>{{ item.old_schedule }}</td>
                <td>{{ item.lesson }}</td> 
                <td>{{ item.type }}</td>
              </tr>
              <tr v-if="detailOffLesson?.data?.length == 0">
                <td colspan="9" class="event-title no-data">Chưa có dữ liệu</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="position-absolute" style="right:40px">
              Tổng số bản ghi: <b style="color:#3699FF">{{ detailOffLesson.pagination.total }}</b>
        </div>
        <div class="d-flex justify-content-center">
          <paginate v-model="detailOffLesson.page" :page-count="detailOffLesson.pagination.last_page" :page-range="3" :margin-pages="1" :click-handler="clickCallbackDetail"
            :prev-text="'Trước'" :next-text="'Sau'" :container-class="'pagination b-pagination'" :pageLinkClass="'page-link'"
            :next-link-class="'next-link-item'" :prev-link-class="'prev-link-item'" :prev-class="'page-link'"
            :next-class="'page-link'" :page-class="'page-item'">
          </paginate>
        </div>
    </div>
      <span slot="footer" class="dialog-footer d-flex justify-content-end">
        <el-button type="secondary" @click="dialogDetailVisible = false">Đóng</el-button>
      </span>
    </el-dialog>

    <!--  chọn ngày -->
    <el-dialog title="Thêm mới lịch nghỉ" :visible.sync="dialogAddDateOffLesson" width="40%">
      <div class="row">
        <div class="col-md-12">
          <label>Thêm mới lịch nghỉ</label>
          <el-date-picker class="w-100" type="dates" format="dd-MM-yyyy" value-format="yyyy-MM-dd" v-model="listDates"
            placeholder="Chọn một hoặc nhiều ngày nghỉ">
          </el-date-picker>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 pt-4 text-right">
          <el-button type="button" class="btn btn-sm btn-secondary font-weight-bold mr-2"
            @click="dialogAddDateOffLesson = false">Hủy bỏ</el-button>
          <el-button type="button" class="btn btn-sm btn-info font-weight-bold mr-2" @click="clickNextAddDate">Tiếp
            theo</el-button>
        </div>
      </div>
    </el-dialog>
    <!--  chọn lớp -->
    <el-dialog title="Thêm mới lịch nghỉ" :visible.sync="dialogAddNewOffLesson" width="60%">
      <dialog_off_lesson ref="dialogOffLesson" @changeListClass="changeListClass" :someProp="this.someProp"
        :listDates="listDates" :confirm="confirm"></dialog_off_lesson>
      <div class="row">
        <div class="col-md-12 pt-4 text-right">
          <el-button type="button" class="btn btn-sm btn-secondary font-weight-bold mr-2" @click="clickReturnClass">Quay
            lại</el-button>
          <el-button type="button" class="btn btn-sm btn-info font-weight-bold mr-2" @click="clickNextClass">Tiếp
            theo</el-button>
        </div>
      </div>
    </el-dialog>
    <!--  xác nhận lịch nghỉ  -->
    <el-dialog title="Thêm mới lịch nghỉ" :visible.sync="dialogConfirmOffLesson" width="60%">
      <div class="row">
        <div class="col-md-3 pt-4">
          <div>Xác nhận lịch học mới</div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 pt-4">
          <el-table @selection-change="handleSelectionChange" :data="tableDataConfirm" border style="width: 100%">
            <el-table-column
              type="selection"
              width="50">
            </el-table-column>
            <el-table-column prop="stt" label="STT" width="80">
            </el-table-column>
            <el-table-column prop="lesson" label="Buổi học" width="100">
            </el-table-column>
            <el-table-column prop="name" label="Lớp học">
            </el-table-column>
            <el-table-column prop="old_date" label="Ngày nghỉ">
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 pt-4 text-right">
          <el-button type="button" class="btn btn-sm btn-secondary font-weight-bold mr-2" @click="clickReturnConfirm">Quay
            lại</el-button>
            <el-button type="button" class="btn btn-sm btn-info font-weight-bold mr-2" @click="clickNextReason">Tiếp theo</el-button>
        </div>
      </div>
    </el-dialog>

    <!--  Lý do nghỉ  -->
    <el-dialog :title="`${!loading_btn_lesson ? 'Lý do nghỉ' : 'Vui lòng đợi'}`" :visible.sync="dialogReasonOffLesson" width="60%">
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form v-if="!loading_btn_lesson" class="form" id="course_create" @submit.prevent="handleSubmit(addMultipleLesson)">
          <div>
            <label for="input-description">Lý do<span class="text-danger"> *</span></label>
            <div class="mb-1">
              <ValidationProvider vid="description" name="Lý do nghỉ" rules="required"
                                  v-slot="{ errors,classes }">
                          <textarea id="input-description" class="form-control" rows="6"
                                    v-model="reasonTableOff"
                                    :class="classes"
                                    placeholder="Nhập lý do nghỉ..."></textarea>
                <div class="fv-plugins-message-container">
                  <div data-field="description" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-md-12 pt-4 text-right">
            <el-button type="button" class="btn btn-sm btn-secondary font-weight-bold mr-2" @click="clickReturnConfirmTable">Quay
              lại</el-button>
            <el-button native-type="submit" type="button" class="btn btn-sm btn-info font-weight-bold mr-2"
              >Xác nhận</el-button>
          </div>
        </form>
        <el-progress v-else class="mx-auto d-flex" style="width: fit-content" type="circle" :percentage="percent_loading"></el-progress>
      </ValidationObserver>
    </el-dialog>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "../../../core/services/store/breadcrumbs.module";
import Multiselect from 'vue-multiselect';
import {
  LIST_REQUEST_OFF_LESSON,
  APPROVE_OFF_LESSON,
  GET_OFF_LESSON,
  APPROVE_LIST_OFF_LESSON,
  DELETE_PROPOSE,
  DETAIL_OFF_LESSON,
  DETAIL_APPROVE_OFF_LESSON 
} from '../../../core/services/store/course/classes.module';

import { GET_CENTERS } from "../../../core/services/store/center/centers.module";
import { GET_BRANCHES } from "../../../core/services/store/center/branch.module";
import dialog_off_lesson from "./dialog_off_lesson";

export default {
  name: "Off-Lesson",

  components: {
    Multiselect,
    dialog_off_lesson,
  },
  data() {
    return {
      page: 1,
      last_page: 1,
      loading_btn_accept: false,
      loading_btn_lesson: false,
      listDates: [],
      someProp: 0,
      query: {
        center_id: '',
        branch_id: '',
        class_name: '',
        teacher_name: '',
        status: '',
        type:'',
        dataTotal: ''
      },
      data: [],
      tableDataConfirm: [],
      reasonTableOff : '',
      dialogFormVisible: false,
      dialogDeleteChangeLesson: false,
      dialogAddDateOffLesson: false,
      dialogAddNewOffLesson: false,
      dialogConfirmOffLesson: false,
      dialogReasonOffLesson: false,
      dialogDetailVisible: false,
      item: {},
      index: 0,
      centers: {},
      branches: {},
      confirm: false,
      loading: false,
      totalData: '',
      listClass : [],
      isConfirm: true,
      detailOffLesson : {
        data : [],
        parent : {},
        pagination: {
          total: 1,
          count: 1,
          per_page: 15,
          current_page: 1,
          last_page: 1
        },
      },
      isBusy: false,
      status_options : [
        {
          id : 2,
          name : 'Đã từ chối',
          style : "background-color:#FF6565"
        },
        {
          id : 0,
          name : 'Chưa duyệt',
          style : "background-color:#FFE27B"
        },
        {
          id : 1,
          name : 'Đã duyệt',
          style : "background-color:#009821; color: #fff"
        },
        {
          id : 3,
          name : 'Đã huỷ',
          style : "background-color:#CACACA"
        }
      ],
      percent_loading : 0,
      isApproveDetail : false
    }
  },
  created(){
  },
  mounted() {
    this.getListRequestOffClass();
    this.getAllCenter();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Quản lý sản xuất", route: 'off-lesson-list' },
      { title: "Quản lý dạy học" },
      { title: "Danh sách buổi nghỉ học" },
    ]);
    this.excitedBroadcasting();
  },
  methods: {
    excitedBroadcasting() {      
      this.$echo.channel('loading_multiple_request_off_lesson').listen('LoadingMultipleRequestOffLesson', (payload) => {
        console.log("payload",payload);
        this.percent_loading = payload.percent;
        if(payload.success && payload.percent >= 100) {
          this.loading_btn_lesson = false;
          this.dialogReasonOffLesson = false;
          this.loading_btn_accept = false;
          this.dialogDeleteChangeLesson = false;
          this.getListRequestOffClass();
        }
      });
    },
    getStatus (status) {
      return this.status_options.find(x => x.id === status);
    },
    addMultipleLesson() {
      this.$confirm('Bạn có chắc chắn muốn thêm ' + this.listClass.length + ' lịch nghỉ', 'Xác nhận', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
      }).then(({ value }) => {
        this.loading_btn_lesson = true;
        this.$store.dispatch(APPROVE_LIST_OFF_LESSON, {
          tableDataConfirm: this.listClass,
          classroom_id: this.item.class_id,
          branch_id: this.item.branch_id,
          center_id: this.item.center_id,
          lesson: this.item.lesson,
          reason: this.reasonTableOff
        }).then((res) => {
          this.listDates = [];
          this.tableDataConfirm = [];
          // this.$bvToast.toast('Thêm lịch nghỉ thành công', {
          //   title: 'Thành công',
          //   variant: 'success',
          //   solid: true,
          // });
        }).catch(() => {
          this.$bvToast.toast('Thêm lịch nghỉ thất bại', {
            title: 'Thất bại',
            variant: 'danger',
            solid: true,
          });
        }).finally(() => {         
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Đã hủy'
        });
      });
    },
    changeListClass(val,count) {
      this.tableDataConfirm = val;
    },
    clickNextAddDate() {
      if (this.listDates.length === 0) {
        this.$message.error('Vui lòng chọn ngày nghỉ');
        return;
      }
      this.someProp = this.someProp + 1;
      this.dialogAddDateOffLesson = false;
      this.dialogAddNewOffLesson = true;
    },
    clickReturnConfirm() {
      this.dialogConfirmOffLesson = false;
      this.dialogAddNewOffLesson = true;
    },
    clickReturnConfirmTable(){
      this.dialogReasonOffLesson = false;
      this.dialogConfirmOffLesson = true;
    },
    clickReturnClass() {
      this.dialogAddNewOffLesson = false;
      this.dialogAddDateOffLesson = true;
    },
    clickNextClass() {
      this.dialogAddNewOffLesson = false;
      this.dialogConfirmOffLesson = true;
    },
    clickNextReason() {
      this.dialogConfirmOffLesson = false;
      this.dialogReasonOffLesson = true;
      this.percent_loading = 0;
      this.reasonTableOff = ''
    },
    getListRequestOffClass() {
      this.loading = true,
      this.$store.dispatch(LIST_REQUEST_OFF_LESSON, {
        page: this.page,
        center_id: this.query.center_id,
        branch_id: this.query.branch_id,
        class_name: this.query.class_name,
        teacher_name: this.query.teacher_name,
        status: this.query.status,
        type: this.query.type,
      }).then((res) => {
        this.totalData = res.pagination.total
        this.data = res.data
        this.last_page = res.pagination.last_page
        this.loading = false
      });

    },
    getAllCenter() {
      this.$store.dispatch(GET_CENTERS, {
        limit: 10000,
        status: 1,
      }).then((res) => {

        this.centers = res.data.data
        if (!res.error) {
          this.centers = res.data.data
        }
      }).catch((e) => {
        console.log(e, 'error')
      });
    },

    getBranches() {
      this.$store.dispatch(GET_BRANCHES, {
        limit: 10000,
        center_id: this.query.center_id
      }).then((res) => {
        if (!res.error) {
          this.branches = res.data.data

        }
      }).catch((e) => {
        console.log(e, 'error')
      });
    },

    detailApproveOffLesson(class_id,lesson) {
      return this.$store.dispatch(DETAIL_APPROVE_OFF_LESSON, {
          class_id: class_id,
          lesson : lesson
        }).then((res) => {
          this.item.content = res.data;
        }).catch((e)=>{
          console.log(e);
        })
    },

    async actionApprove(index, item) {
      this.isApproveDetail = true;
      this.dialogFormVisible = true;
      this.item = item;
      this.index = index;
      await this.detailApproveOffLesson(item.class_id,item.lesson);
      this.isApproveDetail = false;
    },

    actionDetail(item) {
      this.isBusy = true;
      this.dialogDetailVisible = true;
        this.$store.dispatch(DETAIL_OFF_LESSON, {
          id: item.id,
          page : this.detailOffLesson.page
        }).then((res) => {
          this.detailOffLesson = res;
          this.isBusy = false;
        }).catch((e)=>{
          console.log(e);
        })
    },

    actionDeleteLesson(index, item) {
      this.dialogDeleteChangeLesson = true;
      this.item = item;
      this.index = index
    },

    refuseRequestOffClass() {
      this.$prompt('Nhập lý do từ chối', 'Từ chối', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
      }).then(({ value }) => {
        this.$store.dispatch(APPROVE_OFF_LESSON, {
          id: this.item.id,
          status: 2,
          classroom_id: this.item.class_id,
          branch_id: this.item.branch_id,
          center_id: this.item.center_id,
          lesson: this.item.lesson,
          admin_comment: value
        }).then(() => {

          this.$notify.success({
          title: 'Xác nhận',
          message: 'Từ chối duyệt buổi nghỉ thành công!',
          showClose: false
        })
        this.getListRequestOffClass();
          this.dialogFormVisible = false;
        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Đã hủy'
        });
      });
    },

    async acceptRequestOffClass() {
      this.loading_btn_accept = true;
      await this.$store.dispatch(APPROVE_OFF_LESSON, {
        id: this.item.id,
        classroom_id: this.item.class_id,
        branch_id: this.item.branch_id,
        center_id: this.item.center_id,
        lesson: this.item.lesson,
        status: 1,
      }).then((res) => {
        let date_start = res.data.start_date;
        let number_test = res.data.number_test;
        let number_lesson = res.data.number_lesson;
        let calendar = [];
        for (var i = 0; i < res.data.classroom_schedule.length; i++) {
          calendar.push(res.data.classroom_schedule[i].day_of_week);
        }
        let idClass = res.data.id;
        this.hanldeAutoSetEndDate(new Date(date_start), calendar, number_test, number_lesson, idClass);

      }).finally(() => {
        this.getListRequestOffClass();
        this.dialogFormVisible = false;
        this.loading_btn_accept = false;
      });
      this.$bvToast.toast('Duyệt yêu cầu nghỉ học thành công', {
        title: 'Duyệt',
        variant: 'success',
        solid: true
      });
      this.data[this.index].status = 1

    },

    // Xác nhận xoá order duyệt nghỉ
    async acceptDeleteChangeLesson() {
      this.percent_loading = 0;
      this.loading_btn_accept = true;
      await this.$store.dispatch(DELETE_PROPOSE, {
        id: this.item.id,
        classroom_id: this.item.class_id,
        branch_id: this.item.branch_id,
        center_id: this.item.center_id,
        lesson: this.item.lesson,
      }).then((res) => {
        if(res.error){
          this.$notify.error({
          title: 'Xác nhận',
          message: 'Xoá duyệt buổi nghỉ thất bại!',
          showClose: false
        })
        } 
        // else {
        //   this.$notify.success({
        //   title: 'Xác nhận',
        //   message: 'Xoá duyệt buổi nghỉ thành công!',
        //   showClose: false
        // })
        // }
      }).catch((e) => {
          }).finally(() => {

      });
      this.dialogFormVisible = false;
      this.getListRequestOffClass();
    },

    searchData() {
      this.getListRequestOffClass()
    },
    clickCallback(obj) {
      this.page = obj
      this.getListRequestOffClass()
    },
    clickCallbackDetail(obj) {
      this.detailOffLesson.page = obj
      this.actionDetail(this.detailOffLesson.parent)
    },
    hanldeAutoSetEndDate(date_start, calendar, number_test, number_lesson, idClass) {
      let totalSessions = (number_test) + number_lesson;
      let countCalendar = calendar ? calendar.length : 0;
      let offLesson = 0;
      this.$store.dispatch(GET_OFF_LESSON, idClass
      ).then((res) => {
        offLesson = res.data
        if (calendar.includes(date_start.getDay())) {
          calendar = calendar.sort();
          let getIndexDateStart = calendar.indexOf(date_start.getDay());
          //buổi học và không học
          let lesson = countCalendar - getIndexDateStart;
          let noLesson = countCalendar - lesson + offLesson;
          let noLessonDays = countCalendar - lesson == 0 ? Math.min(...calendar) : calendar[countCalendar - lesson];

          //lay tong so ngay khong hoc tuan dau tien
          var totalWeeks = Math.floor((totalSessions + noLesson) / countCalendar);
          let totalDays = (totalWeeks * 7);
          let subtractDays = (totalSessions + noLesson) % countCalendar;
          let subDays = 0;
          if (subtractDays == 0) {
            totalDays -= 7;
            subDays = Math.max(...calendar);
          } else {
            subDays = calendar[subtractDays - 1];
          }
          let addDays = totalDays + subDays - noLessonDays;
          var date_d_start = new Date(date_start);
          let getEndDate = (date_d_start.setDate(date_d_start.getDate() + addDays)) / 1000;
        } else {
          this.notifyService.danger(
            'Ngày khai giảng không khớp với lịch học !'
          );
        }
      });
    },
    handleSelectionChange(data) {
      this.listClass = data.map((val) => {
        return val;
      });
      if(this.listClass.length > 0) {
        this.isConfirm = false;
      }
  }
  }
}

</script>

<style scoped>
.b-toast {
  z-index: 100000;
}

@media screen and (max-width: 1440x) {
  .form-group {
  display: none;
}
}

.btn-footer{
  

}

.title-delete {
  margin: -30px 0 0 0;
}

.form-group {
  width: 199.51px;
}

* {
  font-size: 13px;
}
</style>


